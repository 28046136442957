import { FC, useEffect, useState } from "react";
import css from "./index.module.css";
import cn from "classnames";
import BackButton from "../../../components/UI/Buttons/BackButton/BackButton";
import { DetailedState } from "..";
import RefCardBig from "../../../components/UI/refCardBig";
import RefList from "../../../components/UI/RefList";
import SerachField from "../../../components/UI/SearchField";
import ReferalListHeader from "../../../components/ReferalListHeader";
import Modal from "../../../components/UI/Modals/BuyPremiumModal/Modal";

export interface ReferalHeadersElement {
    value: string;
    label: string;
    isActive: boolean;
}

const REFERAL_HEADERS: ReferalHeadersElement[] = [
    { value: "first_level", label: "1 УРОВЕНЬ", isActive: true },
    { value: "all_refers", label: "ВCЕ РЕФЕРАЛЫ", isActive: false },
    { value: "history", label: "ИСТОРИЯ НАЧИСЛЕНИЙ", isActive: false },
];

const RefDetailed: FC<DetailedState> = ({
    color,
    goBack,
    users,
    money,
    description,
    title,
    refersData,
    userData,
    bonuseTableData,
}) => {
    const [headers, setHeaders] = useState<ReferalHeadersElement[]>(REFERAL_HEADERS);
    const [activeTab, setActiveTab] = useState<string>("first_level");
    const [isTableModalOpen, setTableModal] = useState<boolean>(false);

    const handleClicHeaders = (value: string) => {
        const updatedHeaders = headers.map((item) => {
            if (item.value === value) setActiveTab(value);
            return {
                ...item,
                isActive: item.value === value,
            };
        });
        setHeaders(updatedHeaders);
    };

    useEffect(() => {
        console.log(headers);
    }, [headers]);
    return (
        <>
            <div
                className={cn(css.wrapper, {
                    [css.wrapperBgRed]: color === "red",
                    [css.wrapperBgPurpule]: color === "purple",
                    [css.wrapperBgDacrkGreen]: color === "darck-green",
                    [css.wrapperBgGold]: color === "gold",
                })}
            >
                <div className={css.backBtnWrapper}>
                    <BackButton goBack={goBack} />
                </div>

                <RefCardBig
                    userData={userData}
                    color={color}
                    users={users}
                    money={money}
                    description={description}
                    title={title}
                    setTableModal={setTableModal}
                    showTable={bonuseTableData ? true : false}
                />

                <ReferalListHeader
                    handleClicHeaders={handleClicHeaders}
                    headers={headers}
                    activeTab={activeTab}
                />

                <RefList activeTab={activeTab} />
                {isTableModalOpen && (
                    <Modal
                        type="table"
                        color={color}
                        onClick={() => setTableModal(false)}
                        bonuseTableData={bonuseTableData}
                    />
                )}
            </div>
        </>
    );
};

export default RefDetailed;
