import { FC, useEffect, useState } from "react";
import ReferTitle from "../../components/UI/ReferTitle";
import RefCardsList from "../../components/RefCardsList";
import RefDetailed from "./Detailed";
import Button from "../../components/UI/Buttons/Button";
import { getCookie } from "../../helpers/cockieHelper";
import { api } from "../../api/api";
import { IUserData } from "../interfaces";
import { BonucesTableType } from "../../components/RefCardsList/constants";
import TooltipIcon from "../../components/UI/TooltipIcon";

export type RefScreens = "ref" | "detailedRef" | "notPurchased";
export interface Refers {
    user_id: number;
    email: string;
    profile_pic: string;
    depth: number;
    total_bundles: number;
}

export interface RefersData {
    count: 6;
    next: null;
    previous: null;
    results: Refers[];
}

export interface DetailedState {
    color: string;
    goBack: () => void;
    users: string;
    money: string;
    description: string;
    title: string;
    refersData?: RefersData;
    userData: IUserData;
    bonuseTableData?: BonucesTableType[];
}
interface ReferalProps {
    userData: IUserData;
    setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
}
const Referal: FC<ReferalProps> = ({ userData, setAuthorizedScreen }) => {
    const [refScreen, setRefScreen] = useState<RefScreens>("ref");
    const [detailedState, setDetailedState] = useState({} as DetailedState);
    const [refersData, setRefersData] = useState<RefersData>({} as RefersData);

    useEffect(() => {
        const getRefData = async () => {
            try {
                const response = await api.get<RefersData>(`/profile/me/referrals/`, {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${getCookie("key")?.toString()} `,
                        "Content-Type": "application/json",
                    },
                });
                if (response.data) setRefersData(response.data);
            } catch (error) {
                console.error("Error fetching cards:", error);
            }
        };
        getRefData();
    }, []);

    const refRender = () => {
        switch (refScreen) {
            case "ref":
                return (
                    <div className="pt-16 max-w-xs w-full mx-auto pb-8 flex flex-col gap-4">
                        <div className="relative">
                            <h1 className="text-custom-large tracking-custom-spaced">
                                ПАРТНЕРСКАЯ ПРОГРАММА
                            </h1>
                            <TooltipIcon
                                tooltipText="Здесь Вы можете приобрести ENTER-DAO, любой на выбор или несколько. 
                            В каждом ENTER-DAO вы можете заполнить слоты в пул ликвидности, каждый слот начинает работать
                            с понедельника и в конце недели зачисляется процент на депозит. Подробнее см. в WP."
                            />
                        </div>

                        <RefCardsList
                            userData={userData}
                            setRefScreen={setRefScreen}
                            setDetailedState={setDetailedState}
                            refersData={refersData}
                        />
                    </div>
                );
            case "detailedRef":
                return (
                    <RefDetailed
                        userData={userData}
                        refersData={refersData}
                        color={detailedState.color}
                        goBack={detailedState.goBack}
                        users={detailedState.users}
                        money={detailedState.money}
                        description={detailedState.description}
                        title={detailedState.title}
                        bonuseTableData={detailedState.bonuseTableData}
                    />
                );
            // case "notPurchased":
            //   return (
            //     <div className="pt-16 max-w-xs w-full mx-auto pb-8 flex flex-col gap-4">
            //       <h1 className="text-custom-large tracking-custom-spaced">
            //         ПАРТНЕРСКАЯ ПРОГРАММА НЕДОСТУПНА
            //       </h1>
            //       <div className="w-[342px] absolute bottom-[16%]">
            //         <p className="text-[40px] text-center mb-[48px] text-[#B0B0B0]">
            //           КУПИТЕ DAO, ДЛЯ ДОСТУПА К ПАРТНЕРСКИМ ПРОГРАММАМ
            //         </p>
            //         <Button onClick={() => setAuthorizedScreen("memetrade")}>
            //           Перейти к DAO
            //         </Button>
            //       </div>
            //     </div>
            //   );
        }
    };
    return <>{refRender()}</>;
};

export default Referal;
