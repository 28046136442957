import { FC, useCallback, useEffect, useState } from "react";
import { getCookie } from "../../helpers/cockieHelper";
import KeyboardList from "../../components/KeyboardList";

interface CheckPinCodeScreenProps {
  onSuccess: () => void;
  onFailure?: () => void; 
}

const CheckPinCodeScreen: FC<CheckPinCodeScreenProps> = ({ onSuccess, onFailure }) => {
  const [code, setCode] = useState<string>("****");
  const [title, setTitle] = useState<string>("Введите Пин-код");
  const savedPin = getCookie("password");

  const checkPinCode = useCallback(() => {
    if (code === savedPin) {
      setTitle("Пин-код верный");
      setTimeout(() => {
        onSuccess();
      }, 1000);
    } else {
      setTitle("Неверный пин-код");
      if (onFailure) onFailure();
      setTimeout(() => {
        setCode("****");
        setTitle("Введите Пин-код");
      }, 1000);
    }
  }, [code, savedPin, onSuccess, onFailure]);

  useEffect(() => {
    if (code.indexOf("*") === -1) {
      checkPinCode();
    }
  }, [code, checkPinCode]);

  const handleDelete = useCallback(() => {
    setCode((prev) => {
      if (prev === "****") return prev;

      const lastDigitIndex = prev.search(/\d(?=\*|$)/);

      if (lastDigitIndex === -1) {
        return prev.slice(0, prev.length - 1) + "*";
      }

      return prev.slice(0, lastDigitIndex) + "*" + prev.slice(lastDigitIndex + 1);
    });
  }, []);

  const handleAddNumber = useCallback(
    (number: string) => {
      setCode((prev) => {
        const index = prev.indexOf("*");
        if (index !== -1) {
          return prev.substring(0, index) + number + prev.substring(index + 1);
        }
        return prev;
      });
    },
    []
  );

  return (
    <div className="flex flex-col items-center h-screen w-screen">
      <section className="flex flex-col items-center w-full bg-gradient-to-b from-[#9EE449] to-[#232323] pt-[57px]">
        <span className="text-[56px]">{title}</span>
        <span className="text-[150px]">{code}</span>
      </section>
      <KeyboardList onDelete={handleDelete} onAdd={handleAddNumber} />
    </div>
  );
};

export default CheckPinCodeScreen;
