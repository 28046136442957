import { table } from "console";
import { IUserData } from "../../screens/interfaces";
import { DetailedState, RefersData, RefScreens } from "../../screens/Referal";

interface LicenseDataElement {
    money: string;
    procent: string;
}
export interface BonucesTableType {
    value: string;
    label: string;
    isActive: boolean;
    licenseData: LicenseDataElement[];
}
export const QUICK_START_TABLE: BonucesTableType[] = [
    {
        value: "lvl_1",
        label: "УР.1",
        isActive: true,
        licenseData: [
            { money: "50", procent: "20$" },
            { money: "100", procent: "20$" },
            { money: "200", procent: "20$" },
            { money: "300", procent: "20$" },
            { money: "500", procent: "20$" },
            { money: "1000", procent: "20$" },
            { money: "2000", procent: "20$" },
            { money: "5000", procent: "20$" },
            { money: "10000", procent: "20$" },
        ],
    },
    {
        value: "lvl_2",
        label: "УР.2",
        isActive: false,
        licenseData: [
            { money: "50", procent: "30$" },
            { money: "100", procent: "30$" },
            { money: "200", procent: "30$" },
            { money: "300", procent: "30$" },
            { money: "500", procent: "40$" },
            { money: "1000", procent: "40$" },
            { money: "2000", procent: "40$" },
            { money: "5000", procent: "40$" },
            { money: "10000", procent: "40$" },
        ],
    },
    {
        value: "lvl_3",
        label: "УР.3",
        isActive: false,
        licenseData: [
            { money: "50", procent: "40$" },
            { money: "100", procent: "40$" },
            { money: "200", procent: "40$" },
            { money: "300", procent: "40$" },
            { money: "500", procent: "60$" },
            { money: "1000", procent: "60$" },
            { money: "2000", procent: "80$" },
            { money: "5000", procent: "80$" },
            { money: "10000", procent: "80$" },
        ],
    },
    {
        value: "lvl_4",
        label: "УР.4",
        isActive: false,
        licenseData: [
            { money: "50", procent: "60$" },
            { money: "100", procent: "60$" },
            { money: "200", procent: "60$" },
            { money: "300", procent: "78$" },
            { money: "500", procent: "90$" },
            { money: "1000", procent: "96$" },
            { money: "2000", procent: "120$" },
            { money: "5000", procent: "120$" },
            { money: "10000", procent: "120$" },
        ],
    },
    {
        value: "lvl_5",
        label: "УР.5",
        isActive: false,
        licenseData: [
            { money: "50", procent: "100$" },
            { money: "100", procent: "100$" },
            { money: "200", procent: "100$" },
            { money: "300", procent: "130$" },
            { money: "500", procent: "150$" },
            { money: "1000", procent: "170$" },
            { money: "2000", procent: "200$" },
            { money: "5000", procent: "200$" },
            { money: "10000", procent: "200$" },
        ],
    },
    {
        value: "lvl_6",
        label: "УР.6",
        isActive: false,
        licenseData: [
            { money: "50", procent: "200$" },
            { money: "100", procent: "200$" },
            { money: "200", procent: "200$" },
            { money: "300", procent: "300$" },
            { money: "500", procent: "340$" },
            { money: "1000", procent: "360$" },
            { money: "2000", procent: "400$" },
            { money: "5000", procent: "400$" },
            { money: "10000", procent: "400$" },
        ],
    },
    {
        value: "lvl_7",
        label: "УР.7",
        isActive: false,
        licenseData: [
            { money: "50", procent: "400$" },
            { money: "100", procent: "400$" },
            { money: "200", procent: "400$" },
            { money: "300", procent: "400$" },
            { money: "500", procent: "600$" },
            { money: "1000", procent: "600$" },
            { money: "2000", procent: "800$" },
            { money: "5000", procent: "800$" },
            { money: "10000", procent: "800$" },
        ],
    },
    {
        value: "lvl_8",
        label: "УР.8",
        isActive: false,
        licenseData: [
            { money: "50", procent: "1000$" },
            { money: "100", procent: "1000$" },
            { money: "200", procent: "1000$" },
            { money: "300", procent: "1000$" },
            { money: "500", procent: "1500$" },
            { money: "1000", procent: "1500$" },
            { money: "2000", procent: "2000$" },
            { money: "5000", procent: "2000$" },
            { money: "10000", procent: "2000$" },
        ],
    },
    {
        value: "lvl_9",
        label: "УР.9",
        isActive: false,
        licenseData: [
            { money: "50", procent: "2000$" },
            { money: "100", procent: "2000$" },
            { money: "200", procent: "2000$" },
            { money: "300", procent: "2000$" },
            { money: "500", procent: "3000$" },
            { money: "1000", procent: "3000$" },
            { money: "2000", procent: "4000$" },
            { money: "5000", procent: "4000$" },
            { money: "10000", procent: "4000$" },
        ],
    },
];

export const REFERAL_BONUCE_TABLE: BonucesTableType[] = [
    {
        value: "lvl_1",
        label: "УР.1",
        isActive: true,
        licenseData: [
            { money: "50", procent: "10%" },
            { money: "100", procent: "10%" },
            { money: "200", procent: "10%" },
            { money: "300", procent: "10%" },
            { money: "500", procent: "10%" },
            { money: "1000", procent: "10%" },
            { money: "2000", procent: "10%" },
            { money: "5000", procent: "10%" },
            { money: "10000", procent: "10%" },
        ],
    },
    {
        value: "lvl_2",
        label: "УР.2",
        isActive: false,
        licenseData: [
            { money: "50", procent: "5%" },
            { money: "100", procent: "5%" },
            { money: "200", procent: "6%" },
            { money: "300", procent: "6%" },
            { money: "500", procent: "7%" },
            { money: "1000", procent: "7%" },
            { money: "2000", procent: "8%" },
            { money: "5000", procent: "9%" },
            { money: "10000", procent: "10%" },
        ],
    },
    {
        value: "lvl_3",
        label: "УР.3",
        isActive: false,
        licenseData: [
            { money: "50", procent: "4%" },
            { money: "100", procent: "4%" },
            { money: "200", procent: "5%" },
            { money: "300", procent: "5%" },
            { money: "500", procent: "6%" },
            { money: "1000", procent: "6%" },
            { money: "2000", procent: "7%" },
            { money: "5000", procent: "7%" },
            { money: "10000", procent: "8%" },
        ],
    },
    {
        value: "lvl_4",
        label: "УР.4",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "2%" },
            { money: "200", procent: "3%" },
            { money: "300", procent: "4%" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "6%" },
            { money: "5000", procent: "7%" },
            { money: "10000", procent: "7%" },
        ],
    },
    {
        value: "lvl_5",
        label: "УР.5",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "1%" },
            { money: "500", procent: "3%" },
            { money: "1000", procent: "4%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "6%" },
        ],
    },
    {
        value: "lvl_6",
        label: "УР.6",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "1%" },
            { money: "1000", procent: "1%" },
            { money: "2000", procent: "2%" },
            { money: "5000", procent: "3%" },
            { money: "10000", procent: "3%" },
        ],
    },
    {
        value: "lvl_7",
        label: "УР.7",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "1%" },
            { money: "2000", procent: "2%" },
            { money: "5000", procent: "2%" },
            { money: "10000", procent: "2%" },
        ],
    },
    {
        value: "lvl_8",
        label: "УР.8",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "0.5%" },
            { money: "5000", procent: "0.5%" },
            { money: "10000", procent: "1%" },
        ],
    },
    {
        value: "lvl_9",
        label: "УР.9",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "x" },
            { money: "5000", procent: "0.5%" },
            { money: "10000", procent: "0.5%" },
        ],
    },
    {
        value: "lvl_10",
        label: "УР.10",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "x" },
            { money: "5000", procent: "x" },
            { money: "10000", procent: "0.5%" },
        ],
    },
];

export const MATCHING_BONUCE_TABLE: BonucesTableType[] = [
    {
        value: "lvl_1",
        label: "УР.1",
        isActive: true,
        licenseData: [
            { money: "50", procent: "5% 2ЛП" },
            { money: "100", procent: "5%" },
            { money: "200", procent: "5%" },
            { money: "300", procent: "5%" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_2",
        label: "УР.2",
        isActive: false,
        licenseData: [
            { money: "50", procent: "5%  4ЛП" },
            { money: "100", procent: "5%" },
            { money: "200", procent: "5%" },
            { money: "300", procent: "5%" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_3",
        label: "УР.3",
        isActive: false,
        licenseData: [
            { money: "50", procent: "5%  6ЛП" },
            { money: "100", procent: "5%" },
            { money: "200", procent: "5%" },
            { money: "300", procent: "5%" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_4",
        label: "УР.4",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "5%  8ЛП" },
            { money: "200", procent: "5%" },
            { money: "300", procent: "5%" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_5",
        label: "УР.5",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "5% 10ЛП" },
            { money: "500", procent: "5%" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_6",
        label: "УР.6",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "5% 12ЛП" },
            { money: "1000", procent: "5%" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_7",
        label: "УР.7",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "5% 14ЛП" },
            { money: "2000", procent: "5%" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_8",
        label: "УР.8",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "5% 16ЛП" },
            { money: "5000", procent: "5%" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_9",
        label: "УР.9",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "x" },
            { money: "5000", procent: "5%  18ЛП" },
            { money: "10000", procent: "5%" },
        ],
    },
    {
        value: "lvl_10",
        label: "УР.10",
        isActive: false,
        licenseData: [
            { money: "50", procent: "x" },
            { money: "100", procent: "x" },
            { money: "200", procent: "x" },
            { money: "300", procent: "x" },
            { money: "500", procent: "x" },
            { money: "1000", procent: "x" },
            { money: "2000", procent: "x" },
            { money: "5000", procent: "x" },
            { money: "10000", procent: "5%  20ЛП" },
        ],
    },
];

const LEADERSHIP_POOL_TABLE: BonucesTableType[] = [
    {
        value: "lvl_1",
        label: "Бинарный бонус",
        isActive: true,
        licenseData: [
            { money: "50", procent: "7%" },
            { money: "100", procent: "7%" },
            { money: "200", procent: "8%" },
            { money: "300", procent: "8%" },
            { money: "500", procent: "9%" },
            { money: "1000", procent: "10%" },
            { money: "2000", procent: "11%" },
            { money: "5000", procent: "12%" },
            { money: "10000", procent: "13%" },
        ],
    },
    {
        value: "lvl_2",
        label: "Ограничение по выплатам в неделю",
        isActive: false,
        licenseData: [
            { money: "50", procent: "150$" },
            { money: "100", procent: "300$" },
            { money: "200", procent: "600$" },
            { money: "300", procent: "900$" },
            { money: "500", procent: "1500$" },
            { money: "1000", procent: "3000$" },
            { money: "2000", procent: "6000$" },
            { money: "5000", procent: "15000$" },
            { money: "10000", procent: "30000$" },
        ],
    },
];

export const REF_CARDS = [
    {
        users: 12,
        money: 0,
        color: "darck-green",
        description: "Быстрый старт",
        partners: "Пригласите от 4 партнеров\n и получите бонусы за их покупки Enter-DAO",
        detailed:
            "Выполните условия Новогоднего промо и получите вознаграждение:\n" +
            "• 4 лично приглашенных партнера, которые приобрели любой Enter-DAO\n" +
            "• Личный Enter-Dao номиналом 500 $ или выше",
        tableData: QUICK_START_TABLE,
    },
    {
        users: 3740,
        money: 0,
        color: "purple",
        description: "Реферальный бонус",
        partners: "Поллучайте мгновенные выплаты от 10 уровней ваших рефералов",
        detailed:
            "Реферальный бонус – это вознаграждение за продажи Enter-Dao в личной реферальной структуре.",
        tableData: REFERAL_BONUCE_TABLE,
    },
    {
        users: 8,
        money: 0,
        color: "red",
        description: "Матчинг бонус",
        partners: "От реферального дохода партнеров",
        detailed:
            "Матчинг-бонус представляет собой процент от вознаграждения по Реферальному бонусу партнеров в личной реферальной структуре. Бонус начисляется на баланс моментально после начисления реферального бонуса партнерам. ",
        tableData: MATCHING_BONUCE_TABLE,
    },
    {
        users: 14200,
        money: 0,
        color: "gold",
        description: "ПРОМО",
        partners: "Выполните условия промо и получите вознаграждение",
        detailed: `	Выполните условия 
Новогоднего промо и получите вознаграждение: 
4 лично приглашенных партнера, которые приобрели любой Enter-DAO
Личный Enter-Dao номиналом 500 $ или выше`,
    },
];

export interface IRefCardsList {
    setRefScreen: React.Dispatch<React.SetStateAction<RefScreens>>;
    setDetailedState: React.Dispatch<React.SetStateAction<DetailedState>>;
    refersData?: RefersData;
    userData: IUserData;
}
