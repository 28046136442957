import React from "react";
import clsx from "clsx";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

const Disclaimer: React.FC<IProps> = ({ isOpen, onClose }) => {
    return (
        <div
            className={clsx(
                "fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-[8px] transition-opacity duration-300",
                isOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
            )}
            onClick={onClose}
        >
            <div
                className={clsx(
                    "relative rounded-lg p-4 shadow-lg",
                    "w-[342px] h-[456px] flex flex-col justify-start",
                    "bg-[#232323] backdrop-blur-[64px]"
                )}
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute top-2.5 right-5 text-gray-500 hover:text-gray-700 font-bold text-[20px]"
                >
                    ✕
                </button>
                <h1 className="font-handjet text-[32px] font-semibold leading-[32px] text-left mb-4 text-white">
                    Дисклеймер
                </h1>
                <p
                    className="font-handjet text-[16px] font-medium leading-[17.88px] text-left tracking-[0.01em] text-[#E5E5E5] normal-case"
                    style={{
                        opacity: 0.56,
                        letterSpacing: "1%",
                    }}
                >
                    Настоящий проект децентрализованной автономной организации (DAO) MEME-DAO
                    предоставляется и реализуется исключительно в информационных и развлекательных
                    целях. Участие в проекте не гарантирует получение прибыли, и мы не даем никаких
                    обещаний или гарантий относительно финансовых результатов. Инвестиции в
                    криптовалюты и блокчейн-проекты сопряжены с высоким уровнем риска, и вы можете
                    потерять часть или все свои вложенные средства.
                </p>
                <p
                    className="font-handjet text-[16px] font-medium leading-[17.88px] text-left tracking-[0.01em] text-[#E5E5E5] normal-case mt-4"
                    style={{
                        opacity: 0.56,
                        letterSpacing: "1%",
                    }}
                >
                    Перед принятием каких-либо финансовых решений настоятельно рекомендуем провести
                    собственное исследование и проконсультироваться с квалифицированными
                    специалистами. Мы не несем ответственности за любые убытки или ущерб, возникшие
                    в результате участия в проекте или использования его материалов. Ваше участие в
                    проекте является вашим собственным выбором, и вы принимаете на себя все риски,
                    связанные с ним.
                </p>
            </div>
        </div>
    );
};

export default Disclaimer;
