import { FC } from "react";
import css from "./index.module.css";
import cn from "classnames";
import { BonucesTableType } from "../RefCardsList/constants";

interface ModalTableHeadersProps {
    headers: BonucesTableType[];
    handleClicHeaders: (index: number) => void;
    onClose?: () => void;
}

const ModalTableHeaders: FC<ModalTableHeadersProps> = ({ headers, handleClicHeaders, onClose }) => {
    return (
        <div className={css.wrapper}>
            <button
                onClick={() => {
                    console.log("Close button clicked");
                    if (onClose) onClose();
                }}
                style={{ opacity: 0.48 }}
                className="absolute top-4 right-7 hover:text-gray-700 font-bold text-[20px]"
            >
                ✕
            </button>

            <div className={css.elementsWrapper}>
                {headers.map((item, index) => {
                    return (
                        <p
                            key={item.value}
                            className={cn(css.element, {
                                [css.active]: item.isActive === true,
                            })}
                            onClick={() => {
                                handleClicHeaders(index);
                            }}
                        >
                            {item.label}
                        </p>
                    );
                })}
            </div>
        </div>
    );
};

export default ModalTableHeaders;
