import { Dispatch, FC } from "react";
import { ReactComponent as Bascet } from "./icons/bascet.svg";
import { ReactComponent as Comunity } from "./icons/comunity.svg";
import { ReactComponent as SettingIcon } from "../../images/icons/settings-icon.svg";
import { ReactComponent as Home } from "./icons/home.svg";

const menuItems = [
    { icon: <Home />, label: "MAIN", id: "home" },
    { icon: <Bascet />, label: "MEMETRADE", id: "memetrade" },
    // { icon: <Controler />, label: "ИГРА", id: "game" },
    { icon: <Comunity />, label: "MY GUYS", id: "referal" },
    { icon: <SettingIcon />, label: "SETTINGS", id: "settings" },
];
interface FooterProps {
    setAuthorizedScreen: Dispatch<React.SetStateAction<string>>;
    activeItem: string;
}
const Footer: FC<FooterProps> = ({ setAuthorizedScreen, activeItem }) => {
    const handleClick = (id: string) => {
        setAuthorizedScreen(id);
    };
    return (
        <div className="w-full h-[74px] bg-custom-black fixed bottom-0 left-0 flex justify-evenly items-center text-custom-gray z-50">
            {menuItems.map((item) => (
                <div
                    key={item.id}
                    className={`flex flex-col items-center cursor-pointer gap-1 ${
                        activeItem === item.id ? "text-toxic-green" : "text-custom-gray"
                    }`}
                    onClick={() => handleClick(item.id)}
                >
                    {item.icon}
                    <p className="text-custom-sm tracking-custom-wide">{item.label}</p>
                </div>
            ))}
        </div>
    );
};
export default Footer;
