import React, { useState } from "react";
import clsx from "clsx";
import Disclaimer from "../Disclamer";

interface IProps {
    text: string;
    linkText: {
        label: string;
    };
    classes?: {
        root?: string;
        checkbox?: string;
        text?: string;
        link?: string;
    };
    onChange?: (checked: boolean) => void;
}

const CheckboxWithLink: React.FC<IProps> = ({ text, linkText, classes = {}, onChange }) => {
    const [checked, setChecked] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        const isChecked = e.target.checked;
        setChecked(isChecked);
        onChange?.(isChecked);
    };

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <div className={clsx("flex items-center gap-2", classes.root)}>
            <label
                className={clsx(
                    "w-[44px] h-[44px] rounded-[8px] cursor-pointer flex items-center justify-center transition-colors",
                    {
                        "bg-[#3F3F3F]": !checked,
                        "bg-[#9EE449]": checked,
                    },
                    classes.checkbox
                )}
            >
                <input
                    type="checkbox"
                    className="hidden"
                    checked={checked}
                    onChange={handleChange}
                />
            </label>
            <span className={clsx("font-handjet text-[16px] font-semibold", classes.text)}>
                {text}
            </span>
            <button
                onClick={handleOpenPopup}
                className={clsx(
                    "font-handjet text-[16px] font-semibold underline decoration-solid text-[#9EE449] bg-transparent border-none p-0 cursor-pointer uppercase",
                    classes.link
                )}
            >
                {linkText.label}
            </button>
            <Disclaimer isOpen={isPopupOpen} onClose={handleClosePopup} />
        </div>
    );
};

export default CheckboxWithLink;
