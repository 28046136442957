import React, { useState } from "react";
import clueIcon from "../../../images/icons/clue.png";

interface TooltipIconProps {
    tooltipText: string;
}

const TooltipIcon: React.FC<TooltipIconProps> = ({ tooltipText }) => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const toggleTooltip = () => {
        setIsTooltipVisible(!isTooltipVisible);
    };

    return (
        <div className="absolute top-1 right-8">
            <div
                onClick={toggleTooltip}
                className="absolute w-11 h-11 bg-[#464646] rounded-full flex items-center justify-center cursor-pointer"
            >
                <img src={clueIcon} alt="Подсказка" />
            </div>

            {isTooltipVisible && (
                <div
                    className="absolute right-[-44px] top-12 bg-[#464646] text-white text-sm rounded-[16px] p-4 w-[342px] h-[122px] shadow-lg z-10"
                    style={{
                        fontSize: "16px",
                        lineHeight: "17.88px",
                        letterSpacing: "0.01em",
                    }}
                >
                    {tooltipText}
                </div>
            )}
        </div>
    );
};

export default TooltipIcon;
