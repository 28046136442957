import { FC, useCallback, useEffect, useState } from "react";
import { IUserData } from "../interfaces";
import css from "./index.module.css";
import { ReactComponent as SettingIcon } from "../../images/icons/settings-icon.svg";
import { ReactComponent as SupportIcon } from "../../images/icons/support-icon.svg";
import { ReactComponent as FileIcon } from "../../images/icons/file-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import icon from "../../images/icons/default-icon.png";
import documentIcon from "../../images/icons/Document.svg";
import BalanceCard from "../../components/BalanceCard";
import MoneyStatistic from "../../components/MoneyStatistic/inddex";
import CopyButton from "../../components/UI/Buttons/CopyButton/CopyButton";
import Button from "../../components/UI/Buttons/Button";
import ProfileButton from "../../components/UI/Buttons/ProfileButton/ProfileButton";
import HoverModal from "../../components/HoverModal";
import { RefersData } from "../Referal";
import { api } from "../../api/api";
import { getCookie } from "../../helpers/cockieHelper";
import { formatDate, getWeeksPassed } from "../../utils/dateFormater";
import { HoverModalTypes } from "../../components/HoverModal/interfaces";
import BackButton from "../../components/UI/Buttons/BackButton/BackButton";
import { HistoryDtata } from "../../api/interfaces/history";
import UserRefCard from "../../components/UserReFCard";
import { formatNumber } from "../../utils/numberFormater";
import Modal from "../../components/UI/Modals/BuyPremiumModal/Modal";
import DocsButton from "../../components/UI/Buttons/DocsButton/DocsButton";
import CheckPinCodeScreen from "../CheckPinCodeScreen/CheckPinCodeScreen";

interface MainScreenProps {
  userData: IUserData;
  setAuthorizedScreen: React.Dispatch<React.SetStateAction<string>>;
}

interface StatisticCard {
  depositAmount: number;
  procent: string;
  cardIncome: number;
  date: string;
}

const swiperSettings = {
  spaceBetween: 10,
  slidesPerView: 1,
  pagination: { clickable: true },
  loop: true,
  autoplay: { delay: 1000 },
};

type PageScreen = "main" | "finance";

const MainScreen: FC<MainScreenProps> = ({ userData, setAuthorizedScreen }) => {
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<HoverModalTypes>("withdrawal");
  const [refersCount, setRefersCount] = useState<number>(0);
  const [statisticCard, setStatisticCards] = useState<
    StatisticCard[] | undefined
  >(undefined);
  const userRefLink = `http://t.me/meme_ssl_bot/app?startapp=${userData.refery_code}`;
  const [pageScreen, setPageScreen] = useState<PageScreen>("main");
  const [history, setHistory] = useState<HistoryDtata>({} as HistoryDtata);
  const [avatar, setAvatar] = useState<string>(icon);
  const [name, setName] = useState<string>(userData.name);
  const [isPinCodeOpen, setPinCodeOpen] = useState<boolean>(false);

  useEffect(() => {
    if (userData.profile_pic) {
      setAvatar(userData.profile_pic);
    }
  }, []);

  const getUserData = async () => {
    try {
      const response = await api.get(`/profile/me/`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${getCookie("key")?.toString()} `, // Токен из куки
          "Content-Type": "application/json",
        },
      });

      setAvatar(response.data.profile_pic);
      setName(response.data.name);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const getHistory = async () => {
    try {
      const response = await api.get<HistoryDtata>(
        `/profile/me/history/?type=REFERRAL_FEES&page=1&page_size=500`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) setHistory(response.data);
    } catch (error) {
      console.error("Error fetching cards:", error);
    }
  };

  useEffect(() => {
    getHistory();
  }, [pageScreen]);

  useEffect(() => {
    const newCards = userData.invest_bundles?.map((bundle) => {
      const maxSlots = bundle.max_slots;
      const slots = bundle.slots;
      const price = (+bundle.bundle_price / maxSlots) * slots;
      const procent = bundle.profit_percent;
      const createdDate = formatDate(bundle.created_at);
      const cardInc =
        getWeeksPassed(bundle.created_at) * (+procent / 100) * price;

      return {
        depositAmount: price,
        procent: procent,
        date: createdDate,
        cardIncome: cardInc,
      };
    });
    setStatisticCards(newCards);
  }, [userData]);

  useEffect(() => {
    const getRefData = async () => {
      try {
        const response = await api.get<RefersData>(`/profile/me/referrals/`, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getCookie("key")?.toString()} `,
            "Content-Type": "application/json",
          },
        });

        if (response.data.results.length)
          setRefersCount(response?.data?.results?.length);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };
    getRefData();
  }, []);

  useEffect(() => console.log(refersCount), [refersCount]);

  const handleOpenPinCodeScreen = useCallback(() => {
    setPinCodeOpen(true);
  }, []);

  const handlePinCodeSuccess = useCallback(() => {
    setPinCodeOpen(false); 
    setModalType("withdrawal"); 
    setOpenModal(true); 
  }, []);

  const mainPageRender = () => {
    switch (pageScreen) {
      case "main":
        return (
          <div className={css.wrapper}>
            <div className={css.header}>
              <div className={css.left}>
                <img src={avatar} alt="Profile Avatar" className={css.avatar} />
                <div>
                  <p className={css.username}>{name}</p>
                  <p className={css.userId}>#{userData.id}</p>
                </div>
              </div>
              <div className={css.setingsWrapper}>
                <div
                  onClick={() => {
                    setAuthorizedScreen("settings");
                  }}
                >
                  <SettingIcon />
                </div>
              </div>
            </div>
            <div className={css.UserCards}>
              <BalanceCard
                withdrawMoney={handleOpenPinCodeScreen}
                balance={userData.balance}
              />
            </div>
            {!!statisticCard?.length && (
              <Swiper {...swiperSettings} className="max-w-full">
                {statisticCard?.map((el, index) => (
                  <SwiperSlide key={index}>
                    <div className="w-full mx-auto">
                      <MoneyStatistic
                        depositAmount={el.depositAmount}
                        procent={el.procent}
                        cardIncome={el.cardIncome}
                        date={el.date}
                        userRefLink={userRefLink}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {!!statisticCard?.length && (
              <>
                <div className={css.refWrapper}>
                  <CopyButton
                    value={userRefLink}
                    type="detailed"
                    refersCount={refersCount}
                    bonuces={userData.bonus_balance}
                  />
                </div>
                <div className={css.docsButtonWrapper}>
                  <DocsButton
                    text="INSTRUCTION"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1QOgbp4g5xL1PsF20W0Va39o8694P4E3v/view?usp=sharing"
                  />
                  <DocsButton
                    text="WP MEME-DAO"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1moRGA42nuk_Ewmt_6vqO3NhPhexLDJ-N/view?usp=sharing"
                  />
                  <DocsButton
                    text="FOR INVESTORS"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/16XUBe_IHkRT2FBc3RFxlV8K3q0549hFl/view?usp=sharing"
                  />
                  <DocsButton
                    text="FOR PARTNERS"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1C0_lf0RtEy-nB8gWdKZMF49I2Rm1gRPK/view?usp=sharing "
                  />
                </div>
                <div className={css.financeButtonWrapp}>
                  <Button
                    onClick={() => {
                      setPageScreen("finance");
                    }}
                  >
                    <FileIcon />
                    Финансы
                  </Button>
                </div>
                <ProfileButton
                  Icon={SupportIcon}
                  value={"Поддержка"}
                  onClick={() => {
                    setOpenModal(true);
                    setModalType("support");
                  }}
                />
              </>
            )}
            {statisticCard && !statisticCard?.length && (
              <>
                <div className={css.docsButtonWrapper}>
                  <DocsButton
                    text="INSTRUCTION"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1QOgbp4g5xL1PsF20W0Va39o8694P4E3v/view?usp=sharing"
                  />
                  <DocsButton
                    text="WP MEME-DAO"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1moRGA42nuk_Ewmt_6vqO3NhPhexLDJ-N/view?usp=sharing"
                  />
                  <DocsButton
                    text="FOR INVESTORS"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/16XUBe_IHkRT2FBc3RFxlV8K3q0549hFl/view?usp=sharing"
                  />
                  <DocsButton
                    text="FOR PARTNERS"
                    imageSrc={documentIcon}
                    link="https://drive.google.com/file/d/1C0_lf0RtEy-nB8gWdKZMF49I2Rm1gRPK/view?usp=sharing "
                  />
                </div>
                <Modal
                  type="premium"
                  onClick={() => setAuthorizedScreen("memetrade")}
                  position="statick"
                />
              </>
            )}
            {isOpenModal && (
              <HoverModal
                close={() => setOpenModal(false)}
                type={modalType}
                setModalType={setModalType}
              />
            )}
          </div>
        );
      case "finance":
        return (
          <div className={css.financeWrapper}>
            <BackButton goBack={() => setPageScreen("main")} color={"white"} />
            <h1 className={css.financeTitle}>ФИНАНСЫ</h1>

            <div className={css.historyWrap}>
              {history?.results?.map((item, index) => (
                <div key={index}>
                  <UserRefCard
                    mail={item.detail.email}
                    usdt={`${formatNumber(+item.change)} USDT`}
                    level={formatDate(item.created_at)}
                  />
                </div>
              ))}
            </div>
          </div>
        );
    }
  };

  return (
    <>
      {isPinCodeOpen ? (
        <CheckPinCodeScreen onSuccess={handlePinCodeSuccess} />
      ) : (
        mainPageRender()
      )}
    </>
  );
};

export default MainScreen;
