import { FC } from "react";
import css from "./DocsButton.module.css";
// import { initUtils } from "@telegram-apps/sdk";

interface DocsButtonProps {
  imageSrc: string;
  text: string;
  link?: string;
}

const DocsButton: FC<DocsButtonProps> = ({ imageSrc, text, link }) => {
//   const utils = initUtils();

  const handleClick = () => {
    if (link) {
      window.open(link, "_blank", "noopener,noreferrer");
    //   utils.openLink(link);
    }
  };

  return (
    <div
      className={css.wrapper}
      onClick={handleClick}
      style={{ cursor: link ? "pointer" : "default" }}
    >
      <div className={css.imageContainer}>
        <img src={imageSrc} alt="button-icon" className={css.image} />
      </div>
      <p className={css.text}>{text}</p>
    </div>
  );
};

export default DocsButton;
